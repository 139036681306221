<template>
  <div class="my-1">
    <b-table
      :items="historyData"
      :fields="tableColumns"
      :busy="loading"
      hover
      responsive
      head-variant="info"
      sticky-header
      style="max-height: 65vh"
      thead-class="text-dark align-middle text-nowrap text-left"
      tbody-class="text-dark align-middle text-left"
      class="border-secondary rounded-8px"
      show-empty
      no-border-collapse
      :empty-text="$t('noMatchingResult')"
    >
      <!-- ANCHOR Table Header -->
      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column.key})`]="{ label }"
      >
        <span
          :key="column.label"
          class="text-dark text-nowrap"
        >
          {{ $t(`reservation.historyActions.columns.${label}`) }}
        </span>
      </template>

      <!-- ANCHOR Style for column(s)) -->
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :style="field.style"
        >
      </template>

      <!-- ANCHOR Column orderNo -->
      <template #cell(orderNo)="{ index }">
        <div class="text-center">
          {{ historyData.length - index }}
        </div>
      </template>

      <!-- ANCHOR Column date -->
      <template #cell(date)="{ item }">
        {{ convertISODateTime(item.auditTime).dateTimeSecond }}
      </template>

      <!-- ANCHOR newValue -->
      <template #cell(newValue)="{ item }">
        {{ item.newValue }}
      </template>

      <!-- ANCHOR oldValue -->
      <template #cell(oldValue)="{ item }">
        {{ item.oldValue }}
      </template>

      <template #table-busy>
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BSpinner,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ref } from '@vue/composition-api'

import {
  formatCurrency, convertISODateTime,
} from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    sourceData: {
      type: String,
      default: () => '',
    },
    bookingCodeData: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const { getAuditLogsFromSource, loading } = useReservationHandle()
    const tableColumns = ref([
      { label: 'orderNo', key: 'orderNo', style: 'width: 3em;' },
      { label: 'date', key: 'date', style: 'min-width: 12em' },
      { label: 'executor', key: 'auditUser' },
      { label: 'description', key: 'description', style: 'width: 25%' },
      { label: 'oldValue', key: 'oldValue', style: 'width: 18%' },
      { label: 'newValue', key: 'newValue', style: 'width: 18%' },
    ])
    const historyData = ref([])
    getAuditLogsFromSource({
      source: props.sourceData,
      pnrNumber: props.bookingCodeData,
    })
      .then(res => {
        historyData.value = res.reverse()
      })
    return {
      historyData,
      tableColumns,
      formatCurrency,
      convertISODateTime,
      loading,
      isEmpty,
    }
  },
}
</script>
<style lang="">

</style>
