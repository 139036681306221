var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-1"
  }, [_c('b-table', {
    staticClass: "border-secondary rounded-8px",
    staticStyle: {
      "max-height": "65vh"
    },
    attrs: {
      "items": _vm.historyData,
      "fields": _vm.tableColumns,
      "busy": _vm.loading,
      "hover": "",
      "responsive": "",
      "head-variant": "info",
      "sticky-header": "",
      "thead-class": "text-dark align-middle text-nowrap text-left",
      "tbody-class": "text-dark align-middle text-left",
      "show-empty": "",
      "no-border-collapse": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.historyActions.columns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "table-colgroup",
      fn: function fn(_ref2) {
        var fields = _ref2.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: field.style
          });
        });
      }
    }, {
      key: "cell(orderNo)",
      fn: function fn(_ref3) {
        var index = _ref3.index;
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.historyData.length - index) + " ")])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.auditTime).dateTimeSecond) + " ")];
      }
    }, {
      key: "cell(newValue)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.newValue) + " ")];
      }
    }, {
      key: "cell(oldValue)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.oldValue) + " ")];
      }
    }, {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }